import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { RSVPButton } from "./Header"

const TeaserWrapper = styled.div`
  overflow: hidden;
  position: relative;

  @media (min-width: 576px) {
    max-height: 100vh;
  }

  .teaser-image {
    img {
      top: -20% !important;
    }

    @media (min-width: 576px) {
      filter: brightness(50%);
    }
  }

  ${RSVPButton} {
    display: block;
    @media (min-width: 576px) {
      display: none;
    }
  }

  h1 {
    position: relative;
    font-weight: 700;
    z-index: 999;
    color: #fff;
    font-size: 2.5rem;
    max-width: 60vw;
    letter-spacing: 0.625rem;
    padding: 10px;
    margin-bottom: 7.5rem;

    @media (min-width: 576px) {
      position: absolute;
      display: block;
      max-width: 50vw;
      font-size: calc((75vw - 2rem) / 15);
      font-weight: 400;
      left: 10%;
      top: 17.5%;
    }

    @media (min-width: 1024px) {
      top: 12.5%;
    }
  }
`

export const Teaser = () => {
  return (
    <TeaserWrapper>
      <StaticImage
        className="teaser-image"
        src="../images/Hochzeit-AstridundAlex-Ohhlea-Standesamt-318_websize.jpeg"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Teaser image"
        layout="fullWidth"
      />
      <h1>
        <span>TIME TO CELEBRATE</span>
        <RSVPButton style={{ marginTop: 40 }} href="#form">
          Rückmeldung
        </RSVPButton>
      </h1>
    </TeaserWrapper>
  )
}
