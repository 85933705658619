import * as React from "react"
import { Link, PageProps } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Teaser } from "../components/Teaser"
import { Overview } from "../components/Overview"
import styled from "styled-components"
import { RSVPForm } from "../components/RSVPForm"
import { Divider } from "../components/Seperator.styles"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const IndexPage: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="Home" />
    <Teaser />
    <Container>
      <Overview />
      <Divider />
      <RSVPForm />
    </Container>
  </Layout>
)

export default IndexPage
