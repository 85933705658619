import styled from 'styled-components';

export const SeperatorContainer = styled.article`
  padding: 5vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    flex: 0.5;
    text-align: center;
  }

  h3, h4 {
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }
  }

  section {
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
      flex: 0.5;
      width: 50%;
      padding: 0;
      padding-left: 40px;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`

export const SeperatorItem = styled.div`
  margin: 0 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 0 auto;
  padding: 0px 20px;

  @media (min-width: 992px) {
    margin-bottom: 0px;
    margin: 0;
  }

  & > div {
    @media (min-width: 992px) {
      margin: 0 auto;
    }
  }

  h4 span {
    display: block;
    padding-bottom: 10px;
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }
  }
`

export const Divider = styled.div`
  width: 50vw;
  height: 1px;
  background-color: #fff;
  margin: 0 auto;
`
