import * as React from "react"
import styled from "styled-components"
import { Divider, SeperatorContainer, SeperatorItem } from "./Seperator.styles"

export const Overview = () => {
  return (
    <>
      <SeperatorContainer>
        <h2>Location</h2>
        <section>
          <SeperatorItem>
            <div>
              <h4>
                <span>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://g.page/nooijdutchdeli?share"
                  >
                    Nooij Dutch Deli <br /> Erkrather Str. 30, 40233 Düsseldorf
                  </a>
                </span>
              </h4>
            </div>
          </SeperatorItem>
        </section>
      </SeperatorContainer>

      <Divider />

      <SeperatorContainer>
        <h2>Der Tag</h2>
        <section>
          <SeperatorItem>
            <div>
              <h3>Empfang</h3>
              <h4>
                <span>16h 30m</span>
              </h4>
            </div>
          </SeperatorItem>
          <SeperatorItem>
            <div>
              <h3>gemeinsamer Rückblick auf den 31.12.2021</h3>
              <h4>
                <span>17h 00m</span>
                <span>dazu gibt's Kaffee und Kuchen</span>
              </h4>
            </div>
          </SeperatorItem>
          <SeperatorItem>
            <div>
              <h3>Dinner</h3>
              <h4>
                <span>19h 00m</span>
              </h4>
            </div>
          </SeperatorItem>
          <SeperatorItem>
            <div>
              <h3>Party</h3>
              <h4>
                <span>22h 00m</span>
              </h4>
            </div>
          </SeperatorItem>
          <SeperatorItem>
            <div>
              <h3>Last Dance</h3>
              <h4>
                <span>2h 00m</span>
              </h4>
            </div>
          </SeperatorItem>
        </section>
      </SeperatorContainer>

      <Divider />

      <SeperatorContainer>
        <h2>Generelles</h2>
        <section>
          <SeperatorItem>
            <div>
              <h3>Dresscode</h3>
              <h4>
                <span>chic - gedeckte Farben (schwarz ist erlaubt)</span>
              </h4>
            </div>
          </SeperatorItem>
          <SeperatorItem>
            <div>
              <h3>Stay</h3>
              <h4>
                <span>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://goo.gl/maps/zjddjb5usDHncw5M7"
                  >
                    the niu Tab
                    <br /> Moskauer Str. 29, <br />
                    40227 Düsseldorf
                  </a>
                </span>
              </h4>

              <h4>
                <span>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://goo.gl/maps/hGudLvuEGKD5yKEUA"
                  >
                    Holiday Inn Express Düsseldorf - Hauptbahnhof
                    <br /> Moskauer Str. 30, <br />
                    40227 Düsseldorf
                  </a>
                </span>
              </h4>

              <h4>
                WICHTIGE INFO
                <br />
                <br /> Um die Feier mit euch gemeinsam ausklingen zu lassen,
                würden wir alle Übernachtenden am 10.04.2022 zu einem
                ausgiebigem Brunch einladen. Bucht eure Übernachtung daher ohne
                Frühstück. Wo wir brunchen, teilen wir euch zeitnah mit.
              </h4>
            </div>
          </SeperatorItem>
        </section>
      </SeperatorContainer>

      <Divider />

      <SeperatorContainer>
        <h2>
          Kontaktiert bei Fragen gerne <br />
          unsere Trauzeugen
        </h2>
        <section>
          <SeperatorItem>
            <div>
              <h3>Nicole Kersten</h3>
              <h4>
                <span>
                  <a href="tel:+491788169718">+49 178 8169718</a>
                </span>
              </h4>
            </div>
          </SeperatorItem>

          <SeperatorItem>
            <div>
              <h3>Martin Madej</h3>
              <h4>
                <span>
                  <a href="tel:+4917698202987">+49 176 98202987</a>
                </span>
              </h4>
            </div>
          </SeperatorItem>
        </section>
      </SeperatorContainer>

      <Divider />

      <SeperatorContainer>
        <h2>Was könnt ihr uns schenken?</h2>
        <section>
          <SeperatorItem>
            <div>
              <h3>
                Mit euch gemeinsam endlich unsere Hochzeit zu feiern ist einfach
                das größte Geschenk für uns... <br />
                <span style={{ fontSize: "2.2rem" }}>💸💸💸</span> ist auch
                nicht schlecht
              </h3>
            </div>
          </SeperatorItem>
        </section>
      </SeperatorContainer>
    </>
  )
}
