import * as React from "react"
import { SeperatorContainer } from "./Seperator.styles"
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik"
import styled, { css } from "styled-components"
import Select from "react-select"
import { BsFillPersonPlusFill } from "react-icons/bs"
import * as Yup from "yup"

type InputFieldProps = {
  hasError?: boolean
}

enum FoodTypes {
  MEAT = "meat",
  VEGGI = "veggi",
}

type PersonType = {
  name: string | ""
  food?: FoodTypes | ""
}

type FormType = {
  honeypot: string
  coming: boolean | null
  stay: "niu" | "holiday" | "home" | null
  persons: PersonType[]
}

const INITIAL_VALUES: FormType = {
  honeypot: "",
  coming: null,
  stay: null,
  persons: [
    {
      name: "",
      food: "",
    },
  ],
}

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
`

const FormLabel = styled.label`
  letter-spacing: 0.25rem;
  font-size: 1.25rem;
  font-style: italic;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const PersonFieldsWrapper = styled.div`
  margin-top: 25px;
`

const InputMixin = css`
  font-family: "Didot";
  font-size: 1.25rem;
  margin: 10px 0 20px 0;
  width: 100%;
  height: 38px;
  appearance: none;
  border: none;
  border-radius: 4px;
`

const FormInput = styled.input<InputFieldProps>`
  ${InputMixin}
  padding: 0px 10px;
  box-sizing: border-box;

  ${({ hasError }) =>
    hasError &&
    css`
      margin-bottom: 10px;
      box-shadow: 0px 0px 4px red;
    `}
`

const FormSelect = styled(Select)`
  ${InputMixin}

  .Select__option {
    color: #000;
  }

  .Select__value-container {
    padding: 0px 10px;
  }
`

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  color: #1b1c1d;
  border: 2px solid #fff;
  padding: 10px 0;
  font-family: "Didot";
  text-transform: uppercase;
  font-size: 1.15rem;
  letter-spacing: 0.25rem;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 10px;
  }
`

const FormError = styled.small`
  color: red;
  background-color: beige;
  padding: 2.5px 5px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
`

const SubmitButton = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 12.5px 0;
  font-family: "Didot";
  text-transform: uppercase;
  font-size: 1.15rem;
  letter-spacing: 0.25rem;
  transition: 0.3s all;
  margin-top: 20px;

  &:hover {
    background-color: #fff;
    color: #1b1c1d;
  }
`

const FormFeedback = styled.div<{ hasError: boolean }>`
  background-color: beige;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #000;

  ${({ hasError }) =>
    hasError &&
    css`
      color: red;
    `}
`

const comingOptions = [
  { value: true, label: "Ja, ich komme!" },
  { value: false, label: "Nein, ich komme leider nicht 	☹" },
]

const stayOptions = [
  { value: "niu", label: "Wir übernachten im Niu Tab!" },
  { value: "holiday", label: "Wir übernachten im Holiday Inn!" },
  { value: "home", label: "Wir fahren nach Hause oder schlafen woanders." },
]

const foodOptions = [
  { value: FoodTypes.MEAT, label: "Fleisch" },
  { value: FoodTypes.VEGGI, label: "Vegetarisch" },
]

enum FormStates {
  SUCCESS = "success",
  FAILED = "failed",
}

export const RSVPForm = () => {
  const [formState, setFormState] = React.useState<FormStates | null>(null)
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: Yup.object().shape({
      coming: Yup.boolean()
        .nullable()
        .required("Bitte füll aus, ob du kommst."),
      stay: Yup.string()
        .nullable()
        .when("coming", {
          is: true,
          then: Yup.string()
            .required("Bitte füll aus, wo du übernachten wirst.")
            .nullable(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
      persons: Yup.array().when("coming", {
        is: true,
        then: Yup.array().of(
          Yup.object({
            name: Yup.string().required("Bitte gib deinen Namen an."),
            food: Yup.string().required("Bitte gib einen Essenswunsch an."),
          })
        ),
        otherwise: Yup.array().of(
          Yup.object({
            name: Yup.string().required("Bitte gib deinen Namen an."),
            food: Yup.string(),
          })
        ),
      }),
    }),
    onSubmit: async values => {
      const url =
        typeof window !== "undefined"
          ? `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ""
            }`
          : ""

      if (values.coming === false) {
        console.log(formik.values.persons.length)
      }

      if (values.honeypot !== "") return
      if (values.coming === null) return
      if (
        values.coming === true &&
        (values.persons[0].food === "" || values.persons[0].name === "")
      )
        return

      let response = null
      try {
        response = await fetch(`${url}/api/send-mail`, {
          method: "POST",
          mode: "no-cors",
          body: JSON.stringify(values),
        })
      } catch (error) {
        setFormState(FormStates.FAILED)
      }

      if (response?.status === 200) {
        formik.resetForm()
        setTimeout(() => {
          setFormState(null)
        }, 5000)
        setFormState(FormStates.SUCCESS)
      } else {
        setFormState(FormStates.FAILED)
      }
    },
  })
  return (
    <SeperatorContainer id="form">
      <h2>
        Gebt uns bitte <br /> bis zum 15.03.2021 <br /> eine Rückmeldung
      </h2>
      <section>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <input
              name="honeypot"
              type="hidden"
              value={formik.values.honeypot}
            />
            <FieldArray
              validateOnChange={false}
              name="persons"
              render={arrayHelpers => (
                <div>
                  {formik.values.persons.map((_, index) => (
                    <PersonFieldsWrapper key={index}>
                      <FormGroup>
                        <FormLabel htmlFor={`person.${index}.name`}>
                          <span>Name</span>
                          {index >= 1 && (
                            <div
                              role="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              <u style={{ cursor: "pointer" }}>Entfernen</u>
                            </div>
                          )}
                        </FormLabel>
                        <FormInput
                          hasError={
                            arrayHelpers.form?.errors?.persons &&
                            arrayHelpers.form?.errors?.persons[index] &&
                            arrayHelpers.form?.errors?.persons[index]?.name
                          }
                          id={`persons.${index}.name`}
                          name={`persons.${index}.name`}
                          type="text"
                          onChange={formik.handleChange}
                        />
                        {arrayHelpers.form?.touched?.persons &&
                          arrayHelpers.form?.touched?.persons[index] &&
                          arrayHelpers.form?.touched?.persons[index]?.name &&
                          arrayHelpers.form?.errors?.persons &&
                          arrayHelpers.form?.errors?.persons[index] &&
                          arrayHelpers.form?.errors?.persons[index]?.name && (
                            <FormError>
                              <ErrorMessage name={`persons.${index}.name`} />
                            </FormError>
                          )}
                      </FormGroup>
                      {index === 0 && (
                        <FormGroup>
                          <FormLabel htmlFor="coming">
                            Ich komme zur Hochzeit
                          </FormLabel>
                          <FormSelect
                            options={comingOptions}
                            id="coming"
                            name="coming"
                            classNamePrefix="Select"
                            onChange={(option: any) =>
                              formik.setFieldValue("coming", option.value)
                            }
                          />
                          {formik?.touched?.coming && formik?.errors?.coming && (
                            <FormError>
                              <ErrorMessage name="coming" />
                            </FormError>
                          )}
                        </FormGroup>
                      )}

                      {formik.values.coming && index === 0 && (
                        <FormGroup>
                          <FormLabel htmlFor={`person.${index}.food`}>
                            Übernachtung
                          </FormLabel>
                          <FormSelect
                            options={stayOptions}
                            id="stay"
                            name="stay"
                            classNamePrefix="Select"
                            onChange={(option: any) =>
                              formik.setFieldValue("stay", option.value)
                            }
                          />

                          {formik?.touched?.stay && formik?.errors?.stay && (
                            <FormError>
                              <ErrorMessage name="stay" />
                            </FormError>
                          )}
                        </FormGroup>
                      )}

                      {formik.values.coming && (
                        <FormGroup>
                          <FormLabel htmlFor={`person.${index}.food`}>
                            Essensauswahl
                          </FormLabel>
                          <FormSelect
                            options={foodOptions}
                            id={`persons.${index}.food`}
                            name={`persons.${index}.food`}
                            classNamePrefix="Select"
                            onChange={(option: any) =>
                              formik.setFieldValue(
                                `persons.${index}.food`,
                                option.value
                              )
                            }
                          />

                          {arrayHelpers.form?.touched?.persons &&
                            arrayHelpers.form?.touched?.persons[index] &&
                            arrayHelpers.form?.touched?.persons[index]?.food &&
                            arrayHelpers.form?.errors?.persons &&
                            arrayHelpers.form?.errors?.persons[index] &&
                            arrayHelpers.form?.errors?.persons[index]?.food && (
                              <FormError>
                                <ErrorMessage name={`persons.${index}.food`} />
                              </FormError>
                            )}
                        </FormGroup>
                      )}
                    </PersonFieldsWrapper>
                  ))}
                  {formik.values.coming && (
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.insert(formik.values.persons.length + 1, {
                          name: "",
                          food: "",
                        })
                      }
                    >
                      <BsFillPersonPlusFill />
                      <span>Person hinzufügen</span>
                    </Button>
                  )}
                </div>
              )}
            />
            {formState === FormStates.SUCCESS && (
              <FormFeedback hasError={false}>
                🥳 🥳 🥳 Das Absenden war erfolgreich! Danke für die
                Rückmeldung!🥳 🥳 🥳
              </FormFeedback>
            )}
            {formState === FormStates.FAILED && (
              <FormFeedback hasError={true}>
                💩💩💩 Da ist leider etwas schief gelaufen. Gib mir bitte sofort
                Bescheid! 💩💩💩
              </FormFeedback>
            )}
            <SubmitButton type="submit">Abschicken</SubmitButton>
          </form>
        </FormikProvider>
      </section>
    </SeperatorContainer>
  )
}
